.input-masked {
    font-family: Arial;
    height: 30px;
    color: rgba(74, 74, 74, 1);
    flex: 1 1 0%;
    margin-right: 11px;
    margin-left: 11px;
    margin-top: 14px;
    border: none;
    background: transparent;
    display: flex;
    flex-direction: column;
}